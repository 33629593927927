div.avatar,
img.avatar {
	background-color: white;
	border-color: var(--border-color);
	justify-content: center;
	align-items: center;
	display: inline-flex;

	h4 {
		margin: 0;
	}

	&.xs {
		width: 24px;
		height: 24px;
		border-radius: 4px;
		border-width: 0.25px;
		h4 {
			font-size: 10px;
		}
	}
	&.s {
		width: 32px;
		height: 32px;
		border-radius: 6px;
		border-width: 0.25px;
		h4 {
			font-size: 14px;
		}
	}
	&.ms {
		width: 40px;
		height: 40px;
		border-radius: 8px;
		border-width: 0.25px;
		h4 {
			font-size: 16px;
		}
	}
	&.m {
		width: 48px;
		height: 48px;
		border-radius: 8px;
		border-width: 0.25px;
		h4 {
			font-size: 21px;
		}
	}
	&.l {
		width: 64px;
		height: 64px;
		border-radius: 12px;
		border-width: 0.5px;
		h4 {
			font-size: 28px;
		}
	}
	&.xl {
		width: 100px;
		height: 100px;
		border-radius: 20px;
		border-width: 0.5px;
		h4 {
			font-size: 42px;
		}
	}
	&.xxl {
		width: 132px;
		height: 132px;
		border-radius: 32px;
		border-width: 0.5px;
		h4 {
			font-size: 64px;
		}
	}
	&.circle {
		border-radius: 100%;
	}
}

@primary-color: #66c94a;@link-color: #66c94a;