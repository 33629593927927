.layout-dashboard {
	.layout-dashboard-content {
		.ant-page-header {
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 32px;
			.ant-page-header-heading-title {
				color: var(--gray-dark-color);
				padding-left: 16px;
				border-left: 3px solid var(--gray-dark-color);
				font-size: x-large;
			}
		}
	}
}

@primary-color: #66c94a;@link-color: #66c94a;