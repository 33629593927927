@import '~antd/dist/antd.less';

:root {
	--primary-color: #66c94a;
	--secondary-color: #ff5f2e;
	--border-color: #e9e9e9;
	--success-color: #56a902;
	--error-color: #e53a40;
	--yellow-color: #f9c00c;
	--gray-color: #797979;
	--text-color: #797979;
	--gray-dark-color: #788195;
	--gray-light-color: #e0e0e0;
	--border-color: #e0e0e0;
	--gray-light-light-color: #f5f5f5;
	--white-less-color: #fcfcfc;
	--blue-color: #01aada;
	--background-color: #f1f3f6;
	--dark-color: #2d3446;
	--primary-font: 'Roboto', sans-serif;
	--secondary-fonts: 'Nunito', cursive;
}

#root {
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
}

p {
	color: var(--text-color);
	font-size: 14px;
}

button.ant-btn {
	display: flex;
	align-items: center;
}

button.ant-btn.px-0 {
	padding-left: 0;
	padding-right: 0;
}

.ant-modal-confirm-btns {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	padding-top: 12px;
}

.ant-modal-content .ant-modal-close-x {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ant-modal-footer {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	padding-top: 12px;
}

.ant-modal-footer > div {
	display: flex;
	width: 100%;
	justify-content: flex-end;
}

.ant-input-search .ant-input-suffix {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ant-input-search .ant-input-group-addon button {
	justify-content: center;
}

.ant-page-header-heading-extra {
	display: flex;
}

.table-content {
	overflow: hidden;
	overflow-x: auto;
	background-color: #ffffff;
	border: 1px solid var(--gray-light-color);
}

.table-content thead .ant-table-cell {
	font-size: small;
	text-transform: uppercase;
	font-weight: bolder;
	color: var(--gray-dark-color);
}

.table-content tbody {
	font-size: 14px;
}

.table-content tbody .ant-table-cell {
	padding: 12px 16px;
}

.table-content tr {
	cursor: pointer;
}

.table-content .ant-table-pagination.ant-pagination {
	margin: 16px;
}

.table-content .cell-verified {
	text-align: center;
}

.table-content .anticon {
	vertical-align: 0.05em;
}

.table-content.no-pointer tr {
	cursor: default;
}

.table-content-search {
	width: 100%;
	background: #fafafa;
	padding: 24px;
	border: 1px solid var(--gray-light-color);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

@primary-color: #66c94a;@link-color: #66c94a;