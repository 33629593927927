html,
body,
#root,
#notfound,
#notfound > div {
	height: 100%;
}

#notfound h1 {
	font-family: 'Nunito', sans-serif;
	font-size: 90px;
	color: #ff6600;
	margin-top: 0;
	margin-bottom: 0;
}

#notfound h2 {
	font-size: 24px;
	color: gray;
}

#notfound p {
	color: gray;
}

@primary-color: #66c94a;@link-color: #66c94a;